/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import environments from "./utils/environments";
import {AuthProvider, AuthProviderProps} from "react-oidc-context";
import AccountRestClient from "./services/AccountRestClient";
import {Provider} from "react-redux";
import store from "./store/store";
import LetterManagementRestClient from "./services/LetterManagementRestClient";

export const oidcConfig: AuthProviderProps = {
    authority: environments.authUrl+"/realms/"+environments.authRealm,
    client_id: environments.authClient,
    redirect_uri: window.location.href,
    monitorSession: true,
    automaticSilentRenew: true,
    onSigninCallback: (_user) => {
        window.history.replaceState(
            {},
            document.title,
            window.location.pathname
        )
    }
}

export const accRestClient = new AccountRestClient();
export const letterMgmtRestClient = new LetterManagementRestClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <AuthProvider {...oidcConfig}>
        <React.StrictMode>
            <Provider store={store}>
            <BrowserRouter>
                    <App />
            </BrowserRouter>
            </Provider>
        </React.StrictMode>
    </AuthProvider>
);