/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Main from "./components/layout/Main";
import 'antd/dist/antd.variable.min.css';
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import {ConfigProvider} from "antd";
import AccessDeniedPage from "./pages/AccessDeniedPage";
import ProtectedRoute from "./navigation/Routes/ProtectedRoute";
import OIDCLogin from "./components/login/OIDCLogin";
import PageNotFound from "./pages/PageNotFound";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {loadMyStacks} from "./store/app/app.slice";
import {getAuthenticatedUser} from "./utils/authHelper";
import Portal from "./pages/Portal";
import LetterOverview from "./pages/LetterOverview";
import Dashboard from "./pages/Dashboard";

import StacksOverview from "./pages/StacksOverview";
import StacksDetail from "./pages/StacksDetail";
import MixedStacks from "./components/stacks/MixedStacks";
import StackDetail from "./components/stacks/StackDetail";
import StackLetterDetail from "./components/stacks/StackLetterDetail";
import LetterDetail from "./components/stacks/LetterDetail";

ConfigProvider.config({
    theme: {
        primaryColor: '#755B48'
    },
});

function App() {
    const { loggedInUser} = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const amjUser = getAuthenticatedUser()
    
    useEffect(() => {
        dispatch(loadMyStacks({}));
    }, [loggedInUser]);
    
    
    console.log(window.location.pathname)
        return (
            <OIDCLogin forceLoginRedirect={true}>
                <div className="App">
                    <Switch>
                        <Route exact path="/portal" children={<Portal/>}/>
                        <Main>
                            <Switch>
                                <Route exact path="/letters" children={<LetterOverview/>}/>
                                <Route exact path="/letters/:letterId" children={<LetterDetail/>}/>
                                <Route exact path="/stacks" children={<StacksOverview /> }/>
                                <Route exact path="/stacks/:stackId" children={<StackDetail/>}/>
                                {/* <Route exact path="/stacksDetail" children={<StacksDetail/>}/> */}

                                <Route exact path="/process/:stackType/stacks" children={<MixedStacks/>}/>
                                <Route exact path="/process/:stackType/stacks/:stackId" children={<StackDetail/>}/>
                                <Route exact path="/process/:stackType/stacks/:stackId/:letterId" children={<StackLetterDetail/>}/>
                                
                                <Route exact path="/dashboard" children={<Dashboard/>}/>
                                <Route exact path="/home" children={<Home/>}/>
                                <Route exact path="/tables" children={<ProtectedRoute element={<Tables/>}/>}/>
                                <Route exact path="/billing" children={<ProtectedRoute element={<Billing/>}/>}/>
                                <Route exact path="/rtl" children={<ProtectedRoute element={<Rtl/>}/>}/>
                                <Route exact path="/error" children={<AccessDeniedPage/>}/>
                                {amjUser?.isAdmin() ?
                                    <Redirect exact from="/" to="/letters"/>:
                                    <Redirect exact from="/" to="/letters"/>}
                                <Route path="/" children={<PageNotFound/>}/>
                            </Switch>
                        </Main>
                    </Switch>
                </div>
            </OIDCLogin>
        );
}

export default App;
