import {useDispatch, useSelector} from "react-redux";
import MixedStacksTable from "../tables/MixedStacksTable";
import {useParams} from "react-router-dom";
import EmptyStack from "./EmptyStack";
import StackDetailTable from "../tables/StackDetailTable";
import {useEffect, useState} from "react";
import {loadLetter, loadLettersByStack, loadStack} from "../../store/app/app.slice";
import CreateRequestStackDialog from "../dialogs/CreateRequestStackDialog";
import {Spin} from "antd";
import StackLetter from "./StackLetter";

export default function LetterDetail(props) {
    const dispatch = useDispatch();
    const params = useParams();
    const {currentLetter} = useSelector((state) => state.app);
    
    useEffect(() => {
        dispatch(loadLetter({finalData: {id: params?.letterId}}))
    }, []);
    
    return <>
        { (currentLetter && ""+currentLetter.id === params.letterId) ? <StackLetter key={params.letterId} letter={currentLetter} stack={null} /> : <Spin tip="Loading..."></Spin>}
    </>
}