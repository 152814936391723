import {letterMgmtRestClient} from "../index";

const loadLetters = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getAllLetters();
  return response.data;
};

const loadLetter = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getLetter(data.id);
  return response.data;
};

const loadLettersByStack = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getAllLettersFromStacks(data.stackId);
  return response.data;
};


const loadRequestedStacks = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getAllStackRequests();
  return response.data;
};

const loadStacks = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getAllStacks();
  return response.data;
};

const loadMyStacks = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getAllMyStacks();
  return response.data;
};

const createStackRequest = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().createStackRequest(data.requestedStack);
  return response.data;
};

const assignStack = async (data) => {
  console.log("DATA IN", data)
  const response = await letterMgmtRestClient.letterMgmtApi().assignStack(data.assignedStack);
  return response.data;
};

const getStackRequest = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getStackRequestById(data.id);
  return response.data;
};

const getStack = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getStackById(data.id);
  return response.data;
};

const deleteStack = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().deleteStackById(data.id);
  return response.data;
};

const letterMgmtRestService = {
  loadLetters,
  loadRequestedStacks,
  loadStacks,
  assignStack,
  createStackRequest,
  getStack,
  getStackRequest,
  deleteStack,
  loadMyStacks,
  loadLettersByStack,
  loadLetter
};

export default letterMgmtRestService;