import {Button, Card, message, Table} from "antd";
import {getStatusColor} from "./StacksTables";
import {StackDto} from "ps-letter-mgmtrest-client";
import {useHistory, useParams} from "react-router-dom";
import {PlusOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";

export default function MixedStacksTable(props: {assignedStacks: StackDto[]}) {
    const history = useHistory();
    const params = useParams();
    
    const isStackAssigned = (record: StackDto) => {
        return record.status === "ASSIGNED" || record.status === "CLOSED";
    }
    
    const navigateToLettersView = (record: StackDto) => {
        if(isStackAssigned(record))
        {
            history.push("/process/"+params?.stackType+"/stacks/"+record.id)    
        }else{
            message.info({content: "Stack #" +record.id+" is not assigned yet!"})    
        }
    }

    useEffect(() => {
        if(props.assignedStacks && props.assignedStacks.length === 1 && isStackAssigned(props.assignedStacks[0]))
        {
            navigateToLettersView(props.assignedStacks[0])
        }
    }, [props.assignedStacks]);
    
    const columns = [
        {
            title: "Stack ID",
            dataIndex: "id",
            key: "stackId",
        },
        {
            title: "Supervisor Name",
            dataIndex: "assignerName",
            key: "assignerName",
        },
        {
            title: "Task",
            dataIndex: "",
            render: (stack:StackDto) => stack.assignedStackType ?  stack.assignedStackType : stack.requestedStackType
        },
        {
            title: "Amount",
            dataIndex: "",
            key: "amount",
            render: (stack:StackDto) => stack.assignedAmount ?  stack.assignedAmount : stack.requestedAmount
        },
        {
            title: "Categories",
            dataIndex: "",
            key: "categories",
            render: (stack:StackDto) => stack.assignedCategories ?  stack.assignedCategories : stack.requestedCategories
        },
        {
            title: "Languages",
            dataIndex: "",
            key: "languages",
            render: (stack:StackDto) => stack.assignedLanguages ?  stack.assignedLanguages : stack.requestedLanguages
        },
        {
            title: "Assigned At",
            dataIndex: "assignedAt",
            key: "assignedAt",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status) => (
                <Button type="text" style={{ backgroundColor: getStatusColor(status), color: 'white' }}>
                    {status}
                </Button>
            ),
        },
    ];

    return (
        <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Stacks"
            extra={<Button type="primary" onClick={props.showNewStackRequestModal}><PlusOutlined/></Button>}
        >
            <div className="table-responsive">
                <Table
                    columns={columns}
                    dataSource={props.assignedStacks} // Wrap in array as it's a single object
                    pagination={false}
                    className="ant-border-space"
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => {navigateToLettersView(record)}, // click row
                            onDoubleClick: event => {}, // double click row
                            onContextMenu: event => {}, // right button click row
                            onMouseEnter: event => {}, // mouse enter row
                            onMouseLeave: event => {}, // mouse leave row
                        };
                    }}
                />
            </div>
        </Card>
    );
}