import {useSelector} from "react-redux";
import MixedStacksTable from "../tables/MixedStacksTable";
import {useParams} from "react-router-dom";
import EmptyStack from "./EmptyStack";
import {useState} from "react";
import CreateRequestStackDialog from "../dialogs/CreateRequestStackDialog";

export default function MixedStacks(props) {
    
    const params = useParams();
    const {myStacks} = useSelector((state) => state.app);
    const currentTypeStacks = myStacks[params?.stackType?.toUpperCase()]


    const [openStackRequestDialog, setOpenStackRequestDialog] = useState(false);
    const showModal = () => {
        setOpenStackRequestDialog(true);
    };
    
    const hideModal = () =>  {
        setOpenStackRequestDialog(false);
    }
    
    return <>
        {currentTypeStacks?.length > 0 ? <MixedStacksTable assignedStacks={currentTypeStacks} showNewStackRequestModal={showModal}/> : <EmptyStack showNewStackRequestModal={showModal}/>}
        <CreateRequestStackDialog showModal={openStackRequestDialog} onHideModal={hideModal}/>
    </>
}