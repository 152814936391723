import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import accountRestService from "../../services/acc-rest.service";
import letterMgmtRestService from "../../services/letter-mgmt-rest.service";


const initialState = {
    isUserLoading: false,
    loggedInUser: null,
    letters: [],
    stacks: [],
    stackRequests: [],
    lettersByStack: null,
    currentStack: null,
    currentLetter: null,
    myStacks: {
        FILTRATION: [],
        SUMMARY: [],
        ANSWER: []
        
    }
}

export const getLoggedInUser: any = createAsyncThunk(
    "app/getLoggedInUser",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await accountRestService.loadMe();
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadLetters: any = createAsyncThunk(
    "app/loadLetters",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.loadLetters();
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadLetter: any = createAsyncThunk(
    "app/loadLetter",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.loadLetter(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadMyStacks: any = createAsyncThunk(
    "app/loadMyStacks",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.loadMyStacks();
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return {
                FILTRATION: response.filter(stackDto => stackDto.assignedStackType === "FILTRATION" || (stackDto.assignedStackType === null && stackDto.requestedStackType === "FILTRATION")),
                SUMMARY: response.filter(stackDto => stackDto.assignedStackType === "SUMMARY" || (stackDto.assignedStackType === null && stackDto.requestedStackType === "SUMMARY")),
                ANSWER: response.filter(stackDto => stackDto.assignedStackType === "ANSWER" || (stackDto.assignedStackType === null && stackDto.requestedStackType === "ANSWER"))

            };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const createStackRequest: any = createAsyncThunk(
    "app/createStackRequest",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.createStackRequest(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const assignStackRequest: any = createAsyncThunk(
    "app/assignStackRequest",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.assignStack(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadLettersByStack: any = createAsyncThunk(
    "app/loadLettersByStack",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.loadLettersByStack(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadStack: any = createAsyncThunk(
    "app/loadStack",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.getStack(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadStacks: any = createAsyncThunk(
    "app/loadStacks",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.loadStacks(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadStackRequests: any = createAsyncThunk(
    "app/loadStackRequests",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.loadRequestedStacks(finalData);
            console.log(finalData, response, callbackSuccess, callbackError)
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);



export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setAppState: (state, action) => {
            console.log("Action called setAppState")
            console.log(state)
            console.log(action.payload)
            state = {...state, ...action.payload};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLoggedInUser.pending, (state) => {
                state.isLoggeInUserLoading = true;
            })
            .addCase(getLoggedInUser.fulfilled, (state, action) => {
                state.isLoggeInUserLoading = false;
                state.loggedInUser = action.payload;
            })
            .addCase(getLoggedInUser.rejected, (state, action) => {
                state.isLoggeInUserLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(loadLetters.pending, (state) => {
                state.isLettersLoading = true;
            })
            .addCase(loadLetters.fulfilled, (state, action) => {
                state.isLettersLoading = false;
                state.letters = action.payload;
            })
            .addCase(loadLetters.rejected, (state, action) => {
                state.isLetterLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(loadLetter.pending, (state) => {
                state.isLetterLoading = true;
            })
            .addCase(loadLetter.fulfilled, (state, action) => {
                state.isLetterLoading = false;
                state.currentLetter = action.payload;
            })
            .addCase(loadLetter.rejected, (state, action) => {
                state.isLettersLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(loadLettersByStack.pending, (state) => {
                state.isLettersByStackLoading = true;
            })
            .addCase(loadLettersByStack.fulfilled, (state, action) => {
                state.isLettersByStackLoading = false;
                state.lettersByStack = action.payload;
            })
            .addCase(loadLettersByStack.rejected, (state, action) => {
                state.isLettersByStackLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(loadStack.pending, (state) => {
                state.isCurrentStackLoading = true;
            })
            .addCase(loadStack.fulfilled, (state, action) => {
                state.isCurrentStackLoading = false;
                state.currentStack = action.payload;
            })
            .addCase(loadStack.rejected, (state, action) => {
                state.isCurrentStackLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(createStackRequest.pending, (state) => {
                state.isCreateStackRequestLoading = true;
            })
            .addCase(createStackRequest.fulfilled, (state, action) => {
                state.isCreateStackRequestLoading = false;
                state.myStacks[action.payload.requestedStackType].push(action.payload) 
            })
            .addCase(createStackRequest.rejected, (state, action) => {
                state.isCreateStackRequestLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(loadMyStacks.pending, (state) => {
                state.isCreateStackRequestLoading = true;
            })
            .addCase(loadMyStacks.fulfilled, (state, action) => {
                state.isCreateStackRequestLoading = false;
                state.myStacks = action.payload
            })
            .addCase(loadMyStacks.rejected, (state, action) => {
                state.isCreateStackRequestLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(loadStacks.pending, (state) => {
                state.isStacksLoading = true;
            })
            .addCase(loadStacks.fulfilled, (state, action) => {
                state.isStacksLoading = false;
                state.stacks = action.payload;
            })
            .addCase(loadStacks.rejected, (state, action) => {
                state.isStacksLoading = false;
                state.errorPayload = action.payload;
            })
            .addCase(loadStackRequests.pending, (state) => {
                state.isStackRequestsLoading = true;
            })
            .addCase(loadStackRequests.fulfilled, (state, action) => {
                state.isStackRequestsLoading = false;
                state.stackRequests = action.payload;
            })
            .addCase(loadStackRequests.rejected, (state, action) => {
                state.isStackRequestsLoading = false;
                state.errorPayload = action.payload;
            })
    },
});

export const {setAppState} = appSlice.actions;

export default appSlice.reducer;