
import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Upload,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
  Space,
  Popconfirm,
  Select,
  Input,
  Tooltip, 
  Divider

} from "antd";

import { useState } from "react";
import moment from "moment/moment";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";


const { Option } = Select;
const { Title } = Typography;


export function getCategoryColor(category) {
  // Define color mapping for categories
  const colorMap = {
    Health: "#ffd700",
    Study: "#4169e1",
    Rishta: "#ff6347",
    Jamaat: "#ff0000",
    Parents: "#32cd32",
    Jamia: "#ffa500",
    Children: "#1e90ff",
    Economy: "#8a2be2",
    Testcategory: "#e5005e",
  };
  return colorMap[category] || "#000000";
}

export function getStatusColor(status) {
  // Define color mapping for statuses
  const colorMap = {
    new: "#1890ff",
    answered: "#52c41a",
    summarized: "#faad14",
    unclear: "#ff4d4f",
    LETTER_RECEIVED: "#52c41a",
    LETTER_ASSIGNED: "#faad14",
    REQUESTED: "#1890ff",
    ASSIGNED: "#52c41a",
  };
  return colorMap[status] || "#000000";
}

function StackRequestsTable({ stackRequests, handleRemoveRequest, handleConfirmRequest }) {
  const [editingAmounts, setEditingAmounts] = useState({});
  const [assignmentTypes, setAssignmentTypes] = useState({});

  console.log("STACKREQUEST", stackRequests)
  const columns = [
    {
      title: "Stack ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Worker Name",
      dataIndex: "requesterName",
      key: "requesterName",
    },
    {
      title: "Task",
      dataIndex: "requestedStackType",
      key: "requestedStackType",
    },
    {
      title: "Requested Amount",
      dataIndex: "requestedAmount",
      key: "requestedAmount",
    },
    {
      title: "Requested Language",
      dataIndex: "requestedLanguages",
      key: "requestedLanguages",
      render: (requestedLanguages) => {
        if (!requestedLanguages || !Array.isArray(requestedLanguages)) {
          return <span>-</span>;
        }
        
        const nonNullLanguages = requestedLanguages.filter(lang => lang != null);
        
        if (nonNullLanguages.length === 0) {
          return <span>-</span>;
        }
        
        return <span>{nonNullLanguages.join('/')}</span>;
      },
    },
    {
      title: "Edit Amount",
      key: "editAmount",
      render: (_, record) => (
        <Space>
          <Input
            defaultValue={record.requestedAmount}
            style={{ width: 60, textAlign: 'center' }}
            onChange={(e) => setEditingAmounts({...editingAmounts, [record.id]: e.target.value})}
          />
        </Space>
      ),
    },
    {
      title: "Assignment Type",
      key: "Assignment type",
      render: (_, record) => (
        <Select
          defaultValue="AUTO_ASSIGN"
          style={{ width: 120, backgroundColor: 'brown' }}
          onChange={(value) => setAssignmentTypes({...assignmentTypes, [record.id]: value})}
        >
          <Option value="AUTO_ASSIGN">Auto Assign</Option>
          <Option value="PER_CATEGORY">Per Category</Option>
          <Option value="PER_DATE">Per Date</Option>
        </Select>
      ),
    },
    {
      title: "Accept / Remove Request",
      key: "removeAssignment",
      render: (_, record) => (
        <Space split={<Divider type="vertical" />}>
          <Tooltip title="Confirm Request">
            <Popconfirm
              title="Are you sure you want to confirm this request?"
              onConfirm={() => handleConfirmRequest({
                ...record,
                editedAmount: editingAmounts[record.id] || record.requestedAmount,
                assignmentType: assignmentTypes[record.id] || 'AUTO_ASSIGN'
              })}
              okText="Yes"
              cancelText="No"
            >
              <CheckCircleOutlined style={{ color: 'green', cursor: 'pointer', fontSize: '22px' }} />
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Remove Request">
            <Popconfirm
              title="Are you sure you want to remove this request?"
              onConfirm={() => handleRemoveRequest(record)}
              okText="Yes"
              cancelText="No"
            >
              <CloseCircleOutlined style={{ color: 'red', cursor: 'pointer', fontSize: '22px' }} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <Card
      bordered={false}
      className="criclebox tablespace mb-24"
      title="Stack Requests"
    >
      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={stackRequests} 
          pagination={true}
          className="ant-border-space"
          onRow={(record, rowIndex) => {
            return {
                //onClick: event => {navigateToStackDetailsView(record)}, // click row
                onClick: event => {}, // click row
                onDoubleClick: event => {}, // double click row
                onContextMenu: event => {}, // right button click row
                onMouseEnter: event => {}, // mouse enter row
                onMouseLeave: event => {}, // mouse leave row
            };
        }}
        />
      </div>
    </Card>
  );
}

function AssignedStacksTable({ assignedStacks, navigateToStackDetailsView }) {
  const columns = [
    {
      title: "Stack ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Worker Name",
      dataIndex: "assigneeName",
      key: "assigneeName",
    },
    {
      title: "Task",
      dataIndex: "assignedStackType",
      key: "assignedStackType",
    },
    {
      title: "Assigned Amount",
      dataIndex: "assignedAmount",
      key: "assignedAmount",
    },
    {
      title: "Assigned Languages",
      dataIndex: "assignedLanguages",
      key: "assignedLanguages",
      render: (assignedLanguages) => {
        if (!assignedLanguages || !Array.isArray(assignedLanguages)) {
          return <span>-</span>;
        }
        
        const nonNullLanguages = assignedLanguages.filter(lang => lang != null);
        
        if (nonNullLanguages.length === 0) {
          return <span>-</span>;
        }
        
        return <span>{nonNullLanguages.join('/')}</span>;
      },
    },
    {
      title: "Assigned At",
      // dataIndex: "assignedAt",
      key: "assignedAt",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Button type="text" style={{ backgroundColor: getStatusColor(status), color: 'white' }}>
          {status}
        </Button>
      ),
    },
  ];

  return (
    <Card
      bordered={false}
      className="criclebox tablespace mb-24"
      title="Assigned Stacks"
    >
      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={assignedStacks}
          pagination={true}
          className="ant-border-space"
          onRow={(record, rowIndex) => {
            return {
                onClick: event => {navigateToStackDetailsView(record)}, // click row
                onDoubleClick: event => {}, // double click row
                onContextMenu: event => {}, // right button click row
                onMouseEnter: event => {}, // mouse enter row
                onMouseLeave: event => {}, // mouse leave row
            };
        }}
        />
      </div>
    </Card>
  );
}
// Export the new components
export { StackRequestsTable, AssignedStacksTable };