import React, { useState, useEffect } from 'react';
import { Layout, Drawer, Button } from 'antd';
import { useLocation } from "react-router-dom";
import Sidenav from './Sidebar';
import {MenuUnfoldOutlined} from '@ant-design/icons';
import LetterTable from "../components/tables/LetterTables";
const { Sider, Content } = Layout;

function Portal() {
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const openDrawer = () => setVisible(!visible);

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  useEffect(() => {
    if (pathname === "rtl") {
      setPlacement("left");
    } else {
      setPlacement("right");
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [pathname]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {isMobile && (
         <Button
         type="link"
         icon={<MenuUnfoldOutlined />}
         onClick={openDrawer}
         style={{
           position: 'fixed',
           top: 0,
           left: 0,
           width: '100%',
           height: '50px',
           display: 'flex',
           alignItems: 'center',
           justifyContent: 'flex-start',
           paddingLeft: '16px',
           fontSize: '24px',
           color: 'grey',
         }}
       />
     )}
      <Drawer
        title={false}
        placement={placement === "right" ? "left" : "right"}
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        key={placement === "right" ? "left" : "right"}
        width={250}
        className={`drawer-sidebar ${
          pathname === "rtl" ? "drawer-sidebar-rtl" : ""
        } `}
      >
        <Sidenav color="#1890ff" />
      </Drawer>
      {!isMobile && (
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
          trigger={null}
          width={250}
          theme="light"
          className="sider-primary ant-layout-sider-primary"
        >
          <Sidenav color="#1890ff" />
        </Sider>
      )}
      <Layout>
        <Content style={{ margin: '24px 16px', padding: 24, background: '#fff' }}>
          <LetterTable />
        </Content>
      </Layout>
    </Layout>
  );
}

export default Portal;