
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Upload,
    message,
    Progress,
    Button,
    Avatar,
    Typography,
  } from "antd";
  
  import { ToTopOutlined } from "@ant-design/icons";
  import {Link, useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {UserDataDto, UserDataDtoGenderEnum} from "acc-rest-client";
import {useEffect, useState} from "react";
import {loadLetters} from "../../store/app/app.slice";
import moment from "moment/moment";
import {ProcessedLetter} from "ps-letter-mgmtrest-client";
import {getStatusColor} from "./StacksTables";
  
  
  
const { Title } = Typography;

export const calculate_age = (dob1) => {
  var today = new Date();
  var birthDate = moment.utc(dob1);  // create a date object directly from `dob1` argument
  //var birthDate = moment.utc(dob1);  // create a date object directly from `dob1` argument
  var age_now = today.getFullYear() - birthDate.year();
  // var m = today.getMonth() - birthDate.month();
  // if (m < 0 || (m === 0 && today.getDate() < birthDate.date()))
  // {
  //   age_now--;
  // }
  console.log(age_now);
  return age_now;
}
  
const columnsLetters = [
  {
    title: "Name",
    dataIndex: "originalLetter",
    //sorter: (a, b) => a.projectType?.translations?.en?.localeCompare(b.projectType?.translations?.en),
    render: (letter) => (letter ?

        <Avatar.Group>
          <div style={{marginRight: "3px"}}>
            <Avatar
                src={letter.sender.gender ? letter.sender.gender === UserDataDtoGenderEnum.Male ? "https://cdn-icons-png.flaticon.com/512/10031/10031337.png" : letter.sender.gender === UserDataDtoGenderEnum.Female ? "https://cdn-icons-png.flaticon.com/512/4939/4939798.png" : "https://cdn-icons-png.flaticon.com/512/1534/1534072.png" : "https://cdn-icons-png.flaticon.com/512/1534/1534072.png"}
                shape="square" size="large">{letter.sender.gender}</Avatar>
          </div>
          <div className="avatar-info">
            <Title
                level={5}>{letter.sender.firstName + " " + letter.sender.lastName}</Title>
            <p>{letter.sender.email}</p>
          </div>
        </Avatar.Group> : "N/A"),
  },
  {
    title: "Country",
    dataIndex: ["originalLetter" ,"sender","country", "defaultLabel" ],
    key: "country",
  },
  {
    title: "Jamaat",
    dataIndex: ["originalLetter", "sender","jamaat", "defaultLabel" ],
    key: "jamaat",
  },
  {
    title: "Category",
    dataIndex: ["originalLetter", "category"],
    key: "category",
    render: (category) => (
        <Button type="text" style={{color: getCategoryColor(category),borderColor:getCategoryColor(category) ,borderStyle: 'solid'}}>
          {category}
        </Button>
    ),
  },
  {
    title: "Age",
    dataIndex: ["originalLetter", "sender","birthdate" ],
    render: (birthdate) => (calculate_age(birthdate)),
  },
  {
    title: "Content",
    dataIndex: ["originalLetter", "content"],
    key: "content",
    render: (content) => (content.substring(0, 20) + " ...")
  },
  {
    title: "Hudhur",
    dataIndex: ["presentToHudhur"],
    key: "hudhur",

    render: (hudhur) => (
        <div style={{
          backgroundColor: '#f0f0f0',
          color: 'grey',
          borderRadius: '30%',
          width: '24px',
          height: '24px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '12px',
          fontWeight: 'bold'
        }}>
          {hudhur}
        </div>
    ),
  },
  {
    title: "Status",
    key: "status",
    dataIndex: ["internalStatus"],
    render: (status) => (
        <Button type="text" style={{ backgroundColor: getStatusColor(status),color:'white' }}>
          {status}
        </Button>
    ),
  },
  {
    title: "By",
    dataIndex: ["assignedTo"],
    key: "by",
  },
];
  

  function getCategoryColor(category) {
    // Define color mapping for categories
    const colorMap = {
      Health: "#ffd700",
      Study: "#4169e1",
      Rishta: "#ff6347",
      Jamaat: "#ff0000",
      Parents: "#32cd32",
      Jamia: "#ffa500",
      Children: "#1e90ff",
      Economy: "#8a2be2",
      Testcategory: "#e5005e",
    };
    return colorMap[category] || "#000000";
  }
  
  function LetterTable() {
    const dispatch = useDispatch();
    const {letters} : {letters: ProcessedLetter} = useSelector((state) => state.app);
    const history = useHistory();
    const params = useParams();

    const navigateToLetterView = (record: ProcessedLetter) => {
        history.push("/letters/"+record.id)
    }

      useEffect(() => {
          dispatch(loadLetters({}));
      }, []);
    
    return (
        <>
          <div className="tabled">
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Card
                    bordered={false}
                    className="criclebox tablespace mb-24"
                    title="Letters Overview"
                >
                  <div className="table-responsive">
                    <Table
                        columns={columnsLetters}
                        dataSource={letters}
                        pagination={{pageSize: 10}}
                        className="ant-border-space"
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {navigateToLetterView(record)}, // click row
                                onDoubleClick: event => {}, // double click row
                                onContextMenu: event => {}, // right button click row
                                onMouseEnter: event => {}, // mouse enter row
                                onMouseLeave: event => {}, // mouse leave row
                            };
                        }}
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
          {/* TODO: Remove the below table */}
          {/* <div className="tabled">
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Card
                    bordered={false}
                    className="criclebox tablespace mb-24"
                    title="Letters Overview"
                >
                  <div className="table-responsive">
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={{pageSize: 10}}
                        className="ant-border-space"
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </div> */}
        </>
    );
  }

export default LetterTable;