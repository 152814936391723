import LetterTable from "../components/tables/LetterTables";

function LetterOverview() {
  return (
       <>
           <LetterTable/>
       </> 
      
  );
}

export default LetterOverview;
