import {Button, Card, Form, InputNumber, message, Modal, Result, Select} from "antd";
import {StopOutlined} from "@ant-design/icons";
import {useState} from "react";
import {CreateStackRequestDto} from "ps-letter-mgmtrest-client";
import {useDispatch, useSelector} from "react-redux";
import {createStackRequest} from "../../store/app/app.slice";
import {useParams} from "react-router-dom";

export default function EmptyStack(props) {
    
    return <Card>
        <Result
            icon={<StopOutlined />}
            title="No dedicated letters"
            extra={<Button type="primary" onClick={props.showNewStackRequestModal}>Request Stack</Button>}
        />
    </Card>
}