
// import { useState } from "react";
import { Menu, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { BlockOutlined , SnippetsOutlined, CloudOutlined, MailOutlined, FilterOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");


  return (
   
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            background: 'linear-gradient(to bottom, #71E2CD, #3CC1AD)',
            color: 'white'
          }}>
            <div className="brand" style={{ padding: '40px 0 0 20px', display: 'flex', alignItems: 'center' }}>
            <BlockOutlined  style={{ fontSize: '34px', marginRight: '10px', color: 'white' }} />
            <span style={{ fontSize: '34px' }}>PS Portal</span>
          </div>
            <hr style={{ borderColor: 'rgba(255, 255, 255, 0.2)' }} />
            <Menu theme="dark" mode="inline" style={{ flex: 1, backgroundColor: 'transparent' }}>
            <Menu.Item key="0">
              <NavLink to="/dashboard">
              <SnippetsOutlined  style={{ fontSize: '20px' }} />
                <span className="label">Overview</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="1">
              <NavLink to="/registration-request">
                <CloudOutlined style={{ fontSize: '20px' }} />
                <span className="label">Registration Request</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="2">
              <NavLink to="/letters">
                <MailOutlined style={{ fontSize: '20px' }} />
                <span className="label">Letter Overview</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="3">
              <NavLink to="/filteration">
                <FilterOutlined style={{ fontSize: '20px' }} />
                <span className="label">Filtration</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="4">
              <NavLink to="/summaries">
              <SnippetsOutlined style={{ fontSize: '20px' }} />
                <span className="label">Summaries</span>
              </NavLink>
            </Menu.Item>
          </Menu>
          <Menu theme="dark" mode="inline" style={{ marginTop: 'auto', backgroundColor: 'transparent', marginBottom: '20px' }}>
            <Menu.Item key="5">
              <NavLink to="/profile">
                <UserOutlined style={{ fontSize: '20px' }} />
                <span className="label">Profile</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="6">
              <NavLink to="/logout">
                <LogoutOutlined style={{ fontSize: '20px' }} />
                <span className="label">Logout</span>
              </NavLink>
            </Menu.Item>
          </Menu>
          </div>
      );
    }
    
export default Sidenav;

