// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
// import { Card, Typography, Input, Button, Space, Row, Col } from 'antd';
// import { LeftOutlined, RightOutlined } from '@ant-design/icons';
// import StackCategoryModal from './StackCategoryModal';
// import UserInfoCard from './stackUpdateCard';
// import { loadLettersByStack } from '../../store/app/app.slice';

// const { Title, Text } = Typography;
// const { TextArea } = Input;

// function LetterContentCard({ text }) {
//     return (
//         <Card bordered={false}>
//             <Text>{text}</Text>
//         </Card>
//     );
// }

// export default function StackLetterFiltration() {
    
//     const data = [{
//         key: "1",
//         name: "Alim Ahmad Bhatti",
//         country: "Germany",
//         jamaat: "Friedberg",
//         category: "Health",
//         age: 12,
//         status: "new",
//         content: "Sample content",
//         hudhur: "X",
//         by: "AAB",
//         gender: 'M',
//         language: 'Urdu',
//         text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmodtempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua."
//     },
//     {
//         key: "2",
//         name: "Alim Ahmad Bhatti",
//         country: "Germany",
//         jamaat: "Friedberg",
//         category: "Health",
//         age: 12,
//         status: "old",
//         content: "Sample content",
//         hudhur: "Y",
//         by: "AAB",
//         gender: 'M',
//         language: 'Urdu',
//         text: "hello world! this is my first letter "}]
//     const dispatch = useDispatch();
//     const params = useParams();
//     const { lettersByStack } = useSelector((state) => state.app);
//     const [currentIndex, setCurrentIndex] = useState(0);

//     // useEffect(() => {
//     //     dispatch(loadLettersByStack({ finalData: { stackId: params?.stackId } }));
//     // }, [dispatch, params?.stackId]);

//     const handlePrevious = () => {
//         setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
//     };

//     const handleNext = () => {
//         setCurrentIndex((prevIndex) => (prevIndex < data.length - 1 ? prevIndex + 1 : prevIndex));
//     };

    
//         const currentData = data[currentIndex];
//     return (
//         <Card title="Letter" style={{ width: '100%', backgroundColor:''}}>
//             <Row gutter={16}>
//                 <Col span={12}>
//                     <LetterContentCard text={currentData.text} />
//                 </Col>
//                 <Col span={12}>
//                 <UserInfoCard
//                         name={currentData.name}
//                         gender={currentData.gender}
//                         age={currentData.age}
//                         hudhur={currentData.hudhur}
//                         country={currentData.country}
//                         language={currentData.language}
//                         lists={currentData.lists}
//                         stackType={params.stackType}
//                     />
         
//                 </Col>
//             </Row>
//             <Row justify="space-between" align="middle" style={{ marginTop: 16 }}>
//                 <Col span={24} style={{ textAlign: 'center' }}>
//                     <Space>
//                         <LeftOutlined onClick={handlePrevious} style={{ cursor: 'pointer', fontSize: '18px' }} />
//                         <Text>{currentIndex + 1} of {data.length}</Text>
//                         <RightOutlined onClick={handleNext} style={{ cursor: 'pointer', fontSize: '18px' }} />
//                     </Space>
//                 </Col>
//             </Row>
//         </Card>
//     );
import {Avatar, Tooltip, Comment, Card, Space, Typography} from "antd";
import {UserDataDtoGenderEnum} from "acc-rest-client";
import React from "react";
import moment from "moment/moment";

const {Title} = Typography;
export default function StackLetter({letter, stack}) {
    return <Space align="center">
        <div>
            <Card>
                <Comment
                    author={<a>{letter.originalLetter.sender.firstName + " " + letter.originalLetter.sender.lastName}</a>}
                    avatar={<Avatar
                        src={letter.originalLetter.sender.gender ? letter.originalLetter.sender.gender === UserDataDtoGenderEnum.Male ? "https://cdn-icons-png.flaticon.com/512/10031/10031337.png" : letter.originalLetter.sender.gender === UserDataDtoGenderEnum.Female ? "https://cdn-icons-png.flaticon.com/512/4939/4939798.png" : "https://cdn-icons-png.flaticon.com/512/1534/1534072.png" : "https://cdn-icons-png.flaticon.com/512/1534/1534072.png"}
                        shape="square" size="large">{letter.originalLetter.sender.gender}</Avatar>}
                    content={letter.originalLetter.content}
                    datetime={
                        <Tooltip title="2016-11-22 11:22:33">
                            <span>{moment.utc(letter.originalLetter.metaData.createdAt).format("DD-MM-yyyy")}</span>
                        </Tooltip>
                    }
                />
            </Card>
        </div>
        <div>
            
            <Card>
                <Comment
                    author={"Comments"}
                    content={<p style={{color:"white"}}>{letter.originalLetter.content}</p>}
                />
            </Card></div>
    </Space>
}